<template>
  <travio-center-container grid-width='2/3'>
    <h2 style="color:#636363" class="mb-4">Rewards</h2>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mr-4 sm:mb-0 mb-2" color="primary" @click="addReward" type="filled">Add</vs-button>      
          <vs-button class="mr-4 sm:mb-0 mb-2" color="primary" @click="onBackToRulesList" type="border">Back To Rules</vs-button>      
          <vs-input
            class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Search..."
          />
        </div>
      </div>
      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="rewardsList"
        :gridOptions="gridOptions"
        :autoGroupColumnDef="autoGroupColumnDef"
        rowSelection="single"
        :pagination="false"
        :context="context"
        :suppressPaginationPanel="true"
      >
      </ag-grid-vue>

    </vx-card>
  </travio-center-container>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Vue from "vue"
import { format } from 'date-fns'
import CellRendererRewardsActions from './cell-renderers/CellRendererRewardsActions.vue'
import CellRendererRewardsActiveCheckbox from './cell-renderers/CellRendererRewardsActiveCheckbox.vue'
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';

export default {
  components: {
    AgGridVue,
    CellRendererRewardsActions,
    CellRendererRewardsActiveCheckbox
  },
  props: {
    applicationId: { required: true }
  },
  data () {
    return {
      rewardsList: [],
      categoryList: [],
      autoGroupColumnDef: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererRewardsActions,
        CellRendererRewardsActiveCheckbox
      },
      searchQuery: '',
      selectedCategory: {},
      context: null
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }
    
    if (!this.appHasFeature('rewards')) {
      this.$router.push('/error-404')
    }

    // this.rewardsList = [
    //   { id: 1, name: 'Reward 1', isActive: true, created: '2021-11-08'},
    //   { id: 2, name: 'Reward 2', isActive: true, created: '2022-04-15'},
    // ]

    this.$vs.loading()

    try {
      const response = await this.$http.get(`api/rewardRules/apps/${this.applicationId}/rewards`)
      this.rewardsList = response.data.rewards

    } catch (error) {
      this.$_notifyFailureByResponseData(error.response.data)
    }

    this.$vs.loading.close();

  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'Actions', width: 150, suppressSizeToFit: true, sortable: false, cellRendererFramework: Vue.extend(CellRendererRewardsActions) },
      { field: 'id', width: 120, suppressSizeToFit: true },
      { headerName: 'Name', field: 'name', width: 300,  suppressSizeToFit: false },
      { headerName: 'Is Active', field: 'isActive', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererRewardsActiveCheckbox) },
      {
        headerName: 'Created',
        field: 'created',
        sortable: true,
        suppressSizeToFit: true,
        width: 220,
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat)
      },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 60,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }

    this.autoGroupColumnDef = { minWidth: 200 };
    
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  
  methods: {
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    addReward () {
      this.$router.push({name: 'application-tools-reward-add', 
        params: { 
          applicationId: this.applicationId,
        }
      })
    },
    editReward (rewardId) {
      this.$router.push({name: 'application-tools-reward-edit', 
        params: { 
          applicationId: this.applicationId,
          rewardId: rewardId
        }
      })
    },
    viewReward (rewardId) {
      this.$router.push({name: 'application-tools-reward-view', 
        params: { 
          applicationId: this.applicationId,
          rewardId: rewardId
        }
      })
    },
    async deleteReward (rewardId) {
      this.$vs.loading()
      try {
        const response = await this.$http.delete(`api/rewardRules/apps/${this.applicationId}/rewards/${rewardId}`)
        this.rewardsList = this.rewardsList.filter(x => x.id != rewardId)
        this.$_notifySuccess('Successfully deleted reward.');

      } catch (error) {
        this.$_notifyFailureByResponseData(error.response.data)
      }

      this.$vs.loading.close();
    },
    onBackToRulesList () {
      this.$router.push({ name: 'application-tools-reward-rules-list', 
        params: { 
          applicationId: this.applicationId,
        }
      })
    },

  }
}
</script>

<style>

</style>